<template>
  <div>
    <b-modal
      id="modal-payout-other"
      hide-header
      hide-footer
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
      class="custom__modal"
    >
      <div class="section">
        <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
          <b-row>
            <b-col
              cols="3"
              class="d-flex align-items-center"
            >
              <div
                style="cursor: pointer;"
                @click="directPage"
              >
                <feather-icon
                  size="24"
                  class="text-dark font-weight-bolder"
                  icon="ArrowLeftIcon"
                />
                <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
              </div>
            </b-col>
            <b-col
              cols="6"
              class="d-flex justify-content-center align-items-center"
            >
              <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
                Informasi Pembayaran Lainnya
              </h1>
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >
              <b-button
                class="px-3"
                :disabled="disabledNext()"
                @click="$bvModal.show('modal-payment')"
              >
                Lanjut Pilih Pembayaran
              </b-button>
            </b-col>
          </b-row>
        </header>

        <FormInput :messages="messages" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BModal, BRow, BCol,
} from 'bootstrap-vue'

import FormInput from '@/components/Payout/Other/Modal/Part/FormInput.vue'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    FormInput,
  },
  props: {
  },
  data() {
    return {
      comp: 'FormInput',
      messages: '',
      isLoading: false,
      formPayload: {
        date: '',
        jenis_pembayaran: '',
        tipe_pembayaran: '',
        detail: '',
        receiver: '',
        invoice_number_spt: '',
        amount: '',
        payment_method: '',
        file_attachment: '',
      },
    }
  },
  watch: {
    '$store.state.payout.formPayloadOthers': {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
  },
  methods: {
    disabledNext() {
      if (!this.formPayload.date
      || !this.formPayload.jenis_pembayaran
      || !this.formPayload.tipe_pembayaran
      || !this.formPayload.receiver
      || !this.formPayload.invoice_number_spt
      || this.formPayload.amount == 0) {
        return true
      }
      return false
    },
    directPage() {
      this.$bvModal.hide('modal-payout-other')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
