<template>
  <div class="section" style="overflow: hidden;">
    <div
      class="transaction__filter--container py-2 pl-0 pr-2 justify-content-between"
      style="height:82px;background-color: #f6f7fa;"
    >
      <b-button-group>
        <b-button
          v-for="(item, index) in listFilter"
          v-if="checkPermission(item.permission)"
          :key="`${item.name}-${index}`"
          class="filter--button"
          :class="{ 'active' : item.name == filter }"
          @click="navigation(item)"
        >
          {{ item.name }}
        </b-button>
      </b-button-group>
      <div
        class="d-flex search__export"
        style="width: unset;"
      >
        <!-- <FilterButton /> -->
        <b-button v-if="comp == 'TablePayoutOther' && checkPermission('tambah pembayaran lainnya')" class="bg-white" @click="$bvModal.show('modal-payout-other')">
          Input Pembayaran Lainnya
        </b-button>

      </div>
    </div>

    <keep-alive>
      <TablePayoutSupplier v-if="checkPermission('list pembayaran ke supplier') && comp == 'TablePayoutSupplier'" :is-edit="is_edit" />
      <TablePayoutOther v-if="checkPermission('list pembayaran lainnya') && comp == 'TablePayoutOther'" :is-edit="is_edit" />
      <TableSalesReceipt v-if="checkPermission('list pembayaran penjualan') && comp == 'TableSalesReceipt'" :is-edit="is_edit" />
      <component
        :is="comp"
        :is-edit="is_edit"
      />
    </keep-alive>
    <!-- <ModalInputOther /> -->
  </div>
</template>

<script>
import {
  BButton, BButtonGroup, BDropdown, BDropdownItem, BImg, BPagination, BFormSelect, BModal, VBToggle, BRow, BCol,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'
import FilterButton from '@/components/BaseFilter.vue'
import TableSalesReceipt from '@/components/Payout/SalesReceipt/TableSalesReceipt.vue'
import TablePayoutOther from '@/components/Payout/Other/TablePayoutOther.vue'
import TablePayoutSupplier from '@/components/Payout/Supplier/TablePayoutSupplier.vue'
// import ModalInputOther from '@/components/Payout/Other/Modal/ModalInputOther.vue'

export default {
  title() {
    return 'Pembayaran'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    TableSalesReceipt,
    TablePayoutOther,
    TablePayoutSupplier,
    FilterButton,
    // ModalInputOther,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      // listFilter: ['Pembayaran ke Supplier', 'Pembayaran Lainnya', 'Penjualan'],
      listFilter: [
        {
          name: 'Pembayaran ke Pemasok',
          permission: 'list pembayaran ke supplier',
        },
        {
          name: 'Pembayaran Lainnya',
          permission: 'list pembayaran lainnya',
        },
        {
          name: 'Penjualan',
          permission: 'list pembayaran penjualan',
        },
      ],
      filter: 'Pembayaran ke Pemasok',
      comp: 'TablePayoutSupplier',
      is_edit: null,
    }
  },
  methods: {
    addData(type) {
      this.is_edit = true
      this.$bvModal.show(type)
    },
  },
  computed: {
  },
  created() {},
  mounted() {
    if(this.$route.query.date){
      this.filter = 'Pembayaran Lainnya'
      this.comp = 'TablePayoutOther'
    }
    else if (this.checkPermission('list pembayaran ke supplier')) {
      this.filter = 'Pembayaran ke Pemasok'
      this.comp = 'TablePayoutSupplier'
      this.$router.replace({query: ''})
    } else if (this.checkPermission('list pembayaran lainnya')) {
      this.filter = 'Pembayaran Lainnya'
      this.comp = 'TablePayoutOther'
      this.$router.replace({query: ''})
    } else if (this.checkPermission('list pembayaran penjualan')) {
      this.filter = 'Penjualan'
      this.comp = 'TableSalesReceipt'
      this.$router.replace({query: ''})
    }
  },
  methods: {
    navigation(item) {
      try {
        if (this.$route.query.p !== item.name) {
          this.$router.replace({query: { p: item.name, page: 1 }})
        }
        this.filter = item.name
        this.comp = (item.name == 'Pembayaran ke Pemasok' ? 'TablePayoutSupplier' : (item.name == 'Pembayaran Lainnya' ? 'TablePayoutOther' : 'TableSalesReceipt') )
        this.is_edit = null
      } catch (error) {
        
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

</style>
